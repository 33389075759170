//  廣告欄位 N101 首頁、新聞列表頁側邊
'use client';

import { useState, useEffect } from 'react';
import promoClient from '@/common/apiClient/promoClient';
import Image from 'next/image';
import { FOOTER_SOCIAL_LINK, PROMO_BANNER_POSITION_CODE } from '@/common/constant';

const AdN101 = ({ location = null }) => {
    // 社群 banner
    const [banner, setBanner] = useState(null);

    // 取得社群 banner
    const getBanner = async () => {
        try {
            const { banners } = await promoClient.getPromoBanner(
                PROMO_BANNER_POSITION_CODE.ASIDE_SOCIAL
            );

            if (banners && banners.length) setBanner(banners[0]);
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        getBanner();
    }, []);

    return (
        <div className={`${location === 'news' ? 'relative rounded-[4px]' : ''} ulg:hidden`}>
            <a
                className={`overflow-hidden ${
                    location !== 'news' ? 'index-right-banner' : ''
                } w-full block ulg:w-0 ulg:h-0 overflow-hidden after:content-none ${
                    !banner?.image?.includes('.mp4') ? 'aspect-[330/412]' : ''
                }`}
                href={banner?.link || '/'}
                target="_blank"
                rel="noreferrer noopener"
                aria-label="advertisement"
            >
                {/* 可能是影片或圖片 */}
                {banner ? (
                    banner?.image?.includes('.mp4') ? (
                        <video
                            autoPlay
                            muted
                            loop
                            playsInline
                            className="w-full object-cover aspect-[307/410]"
                        >
                            <source src={banner?.image} />
                        </video>
                    ) : (
                        <img
                            alt={banner?.subject || 'promotion'}
                            src={
                                banner?.image?.includes('instagram')
                                    ? `/api/proxy?url=${encodeURIComponent(banner?.image)}`
                                    : banner?.image || '/images/news/default_related.svg'
                            }
                        />
                    )
                ) : (
                    <div className="w-full aspect-[330/412] img-box" />
                )}
            </a>
            <div
                className={`py-[13px] overflow-hidden bg-[#141414] relative flex center-center
              ${location === 'news' ? 'space-x-[22px]' : 'space-x-[8px]'}`}
            >
                {/* 社群連結 */}
                {FOOTER_SOCIAL_LINK.map((link, i) => (
                    <a
                        key={i + link.source}
                        href={link.link}
                        target="_blank"
                        rel="noreferrer noopener"
                        className="trans hover:translate-y-[-3px]"
                    >
                        <Image
                            src={
                                `/images/icon/icon_aside_${link.source}.svg` ||
                                '/images/news/default_related.svg'
                            }
                            alt={link}
                            width={30}
                            height={30}
                        />
                    </a>
                ))}
            </div>
        </div>
    );
};

export default AdN101;
